import * as THREE from "three";
import TouchTexture from "./TouchTexture";
import { EffectComposer, RenderPass, EffectPass } from "postprocessing";
import { WaterEffect } from "./WaterEffect";
import { Planes } from "./Planes";

const image1 = require("./static/background-image.jpg");

let images = [image1];

export default class App {
  static winHeight = () => window.innerHeight + 150;
  static winWidth = () => window.innerWidth;
  constructor(props) {
    const width = App.winWidth();
    const height = App.winHeight();
    this.renderer = new THREE.WebGLRenderer({
      antialias: false
    });
    this.rendererSize = { width, height };
    this.renderer.setSize(width, height);
    this.renderer.setPixelRatio(window.devicePixelRatio);

    this.composer = new EffectComposer(this.renderer);

    props.root.append(this.renderer.domElement);
    this.renderer.domElement.id = "webGLApp";

    this.camera = new THREE.PerspectiveCamera(45, width / height, 0.1, 10000);
    this.camera.position.z = 50;
    this.disposed = false;
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0x161624);

    this.clock = new THREE.Clock();

    this.assets = {};
    this.raycaster = new THREE.Raycaster();
    this.hitObjects = [];

    this.touchTexture = new TouchTexture();

    this.data = {
      images: images
    };

    this.subjects = [
      new Planes(this, images)
      // new Text(this, this.data.text[1])
    ];
    // this.subjects = [];

    this.tick = this.tick.bind(this);
    this.onResize = this.onResize.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);

    this.init = this.init.bind(this);
    this.loader = new Loader();
    this.loadAssets().then(this.init);
  }
  loadAssets() {
    const loader = this.loader;
    return new Promise((resolve, reject) => {
      // loadTextAssets(assets, loader);

      this.subjects.forEach(subject => subject.load(loader));

      loader.onComplete = () => {
        resolve();
      };
    });
  }
  onPlaneHover(i) {
    // const text = this.subjects[1];
    // if (i === 0) {
    //   text.updateText("DON'T");
    // } else if (i === 1) {
    //   text.updateText("LOOK");
    // } else if (i === 2) {
    //   text.updateText("BACK");
    // }
  }
  initComposer() {
    const renderPass = new RenderPass(this.scene, this.camera);
    this.waterEffect = new WaterEffect({ texture: this.touchTexture.texture });
    const waterPass = new EffectPass(this.camera, this.waterEffect);
    waterPass.renderToScreen = true;
    renderPass.renderToScreen = false;
    this.composer.addPass(renderPass);
    this.composer.addPass(waterPass);
  }
  init() {
    this.touchTexture.initTexture();
    // this.initTextPlane();
    // this.addHitPlane();
    this.subjects.forEach(subject => subject.init());
    this.initComposer();

    this.tick();

    window.addEventListener("resize", this.onResize);
    window.addEventListener("mousemove", this.onMouseMove);
    window.addEventListener("touchmove", this.onTouchMove);
  }
  onTouchMove(ev) {
    const touch = ev.targetTouches[0];
    this.onMouseMove({ clientX: touch.clientX, clientY: touch.clientY });
  }
  onMouseMove(ev) {
    // const raycaster = this.raycaster;
    this.mouse = {
      x: ev.clientX / App.winWidth(),
      y: 1 - ev.clientY / App.winHeight()
    };
    this.touchTexture.addTouch(this.mouse);

    // raycaster.setFromCamera(
    //   {
    //     x: (ev.clientX / App.winWidth()) * 2 - 1,
    //     y: -(ev.clientY / App.winHeight()) * 2 + 1
    //   },
    //   this.camera
    // );
    // this.subjects.forEach(subject => {
    //   if (subject.onMouseMove) {
    //     subject.onMouseMove(ev);
    //   }
    // });
  }

  getViewSize() {
    const fovInRadians = (this.camera.fov * Math.PI) / 180;
    const height = Math.abs(
      this.camera.position.z * Math.tan(fovInRadians / 2) * 2
    );

    return { width: height * this.camera.aspect, height };
  }
  dispose() {
    this.disposed = true;
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("mousemove", this.onMouseMove);
    this.scene.children.forEach(child => {
      child.material.dispose();
      child.geometry.dispose();
    });
    if (this.assets.glyphs) this.assets.glyphs.dispose();

    this.hitObjects.forEach(child => {
      if (child) {
        if (child.material) child.material.dispose();
        if (child.geometry) child.geometry.dispose();
        // child.dispose();
      }
    });
    if (this.touchTexture) this.touchTexture.texture.dispose();
    this.scene.dispose();
    this.renderer.dispose();
    this.composer.dispose();
  }
  update() {
    this.touchTexture.update();
    this.subjects.forEach(subject => {
      subject.update();
    });
  }
  render() {
    // this.renderer.render(this.scene, this.camera);
    this.composer.render(this.clock.getDelta());
  }
  tick() {
    if (this.disposed) return;
    this.render();
    this.update();
    requestAnimationFrame(this.tick);
  }
  onResize() {
    if (
      App.winWidth() <= this.rendererSize.width &&
      App.winHeight() <= this.rendererSize.height
    ) {
      return null;
    }
    const width = App.winWidth();
    const height = App.winHeight();
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
    this.rendererSize = { width, height };
    this.composer.setSize(width, height);
    this.subjects.forEach(subject => {
      subject.onResize(width, height);
    });
  }
}

class Loader {
  constructor() {
    this.items = [];
    this.loaded = [];
  }
  begin(name) {
    this.items.push(name);
  }
  end(name) {
    this.loaded.push(name);
    if (this.loaded.length === this.items.length) {
      this.onComplete();
    }
  }
  onComplete() {}
}
