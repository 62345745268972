import React, { useEffect, useState } from "react";

import "./App.scss";
import logo from "./images/logo.svg";
import Form from "./components/Form";
import { Title } from "./components/Title";
import { Body } from "./components/Body";
import { Details } from "./components/Details";

function App() {
  const [reveal, triggerReveal] = useState(false);
  // give the webgl some time to render before rendering the app
  useEffect(() => {
    setTimeout(() => {
      triggerReveal(true);
    }, 100);
  }, []);
  return reveal ? (
    <div className="app">
      <div className="app-wrap">
        <div className="app-logo-wrap">
          <a href="https://hyam.de">
            <img src={logo} className="app-logo" alt="logo" />
          </a>
        </div>
        <div className="app-wrap-title">
          <Title />
        </div>
        <div className="app-wrap-details">
          <Body />
          <Details />
          <Form />
        </div>
      </div>
    </div>
  ) : null;
}

export default App;
