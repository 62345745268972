import { keyframes } from "styled-components";

export const yellow = "#FEEF27";
export const secondary = "#EE6638";
export const error = "#EE3838";
export const medium = "768px";
export const large = "1000px";
export const xlarge = "1440px";

export const appear = keyframes`
from {
  transform: translate3d(0,100px,0);
  opacity:0;
}

to {
  transform:  translate3d(0,0,0);
  opacity:1;
}
`;

/**
 * Computes a CSS calc function that betweens a value from
 * A to B over viewport-width A to viewport-width B.
 * Requires a media query to cap the value at B.
 */
export function between(from, to, fromWidth, toWidth) {
  const slope = (to - from) / (toWidth - fromWidth);
  const base = from - slope * fromWidth;

  return `calc(${base}px + ${100 * slope}vw);`;
}

export function staggeredAppear(step) {
  // const delay = 0.1 + [0, 0.1, 0.15, 0.175][step];
  const delay = 0.1 * step;

  return `
  animation-delay: ${delay}s;
  animation-fill-mode: forwards;
  opacity: 0;`;
}
