import React from "react";
import styled from "styled-components";

import { yellow, secondary } from "../../utils/mixins";

const Fieldset = styled.fieldset`
  margin: 0;
  padding: 0;
  border: none;
  /* margin-right: 16px; */

  select {
    position: relative;
    display: block;
    height: 56px;
    width: 100%;

    padding: 0 40px 0 16px;
    font: inherit;
    color: ${yellow};
    border: 4px solid ${yellow};
    border-radius: 0;
    appearance: none;
    background: transparent;
    text-transform: uppercase;

    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10px' width='20px'%3E%3Ctext x='0' y='10' fill='${encodeURIComponent(
      yellow
    )}'%3E%E2%96%BE%3C/text%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 1.5em 1em;
    background-position: right center;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      padding: 20px;
      border-left: 2px solid red;
      border-bottom: 2px solid red;
      background: #000;
    }
  }
  select::-ms-expand {
    display: none;
  }
  select:hover {
    border-color: ${secondary};
  }
  select:focus {
    border-color: ${secondary};
    outline: none;
  }
  select option {
    font-weight: normal;
  }
`;

const Wrapper = styled.div`
  max-width: 100%;
  margin: 0 8px 16px;
`;

const Input = ({
  name,
  options = [],
  required,
  theme,
  value,
  placeholder,
  onChange
}) => {
  return (
    <Fieldset theme={theme}>
      <Wrapper>
        <select {...{ name, required, value, onChange }}>
          <option value="" disabled hidden>
            {placeholder}
          </option>
          {options.map(({ value, label }) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </select>
      </Wrapper>
    </Fieldset>
  );
};

export default Input;
