import React from "react";
import styled from "styled-components";

import { medium, appear } from "../../utils/mixins";
import image1 from "../../images/title-1.svg";
import image2 from "../../images/title-2.svg";
import image3 from "../../images/title-3.svg";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  margin: 7% 0 2%;
  animation: ${appear} 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  div {
    margin: 1% auto;
    &:nth-child(1) {
      width: 44.771723123%;
    }
    &:nth-child(2) {
      width: 85.6161021124%;
    }
    &:nth-child(3) {
      width: 59.3028964174%;
    }
  }
  img {
    flex: 1;
    width: 100%;
  }

  @media (min-width: ${medium}) {
    max-width: 90%;
  }

  @media (orientation: landscape) {
    flex-direction: row;
    max-width: 90%;

    div {
      &:nth-child(1) {
        flex: 22.3858615615%;
        margin-right: 2.4349533628%;
      }
      &:nth-child(2) {
        flex: 42.8080510562%;
      }
      &:nth-child(3) {
        flex: 29.6514482087%;
        margin-left: 2.7196858125%;
      }
    }
  }
`;

const TitleComponent = () => {
  return (
    <Wrapper>
      <div>
        <img src={image1} alt="It's" />
      </div>
      <div>
        <img src={image2} alt="about" />
      </div>
      <div>
        <img src={image3} alt="time" />
      </div>
    </Wrapper>
  );
};

export default TitleComponent;

// 228 + 24.8 (24,8208149244)
//  436 (42,8080510562)
//   27.7 + 302 (32,3711340212)

//   =
//   1.018,5

//   (228 + 24,8 ) * 0,09818360334
