import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Background from "./components/Background";
import { initialiseDatabase } from "./utils/database";

initialiseDatabase();

function isIpad() {
  const ua = window.navigator.userAgent;
  if (ua.indexOf("iPad") > -1) {
    return true;
  }

  if (ua.indexOf("Macintosh") > -1) {
    try {
      document.createEvent("TouchEvent");
      return true;
    } catch (e) {}
  }

  return false;
}

// handle overscroll styling for ios
if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i) || isIpad()) {
  document.documentElement.classList.add("ios");
}

new Background({ root: document.getElementById("background") });

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
