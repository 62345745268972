import React from "react";
import styled from "styled-components";

import { between, xlarge, appear, staggeredAppear } from "../../utils/mixins";

// import { createUniqueName } from '../../utils/forms'

const Copy = styled.p`
  margin-bottom: 32px;
  font-size: ${between(20, 31, 320, 1024)};
  line-height: 1em;
  letter-spacing: -0.25px;
  text-transform: uppercase;
  animation: ${appear} 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  ${staggeredAppear(1)}
  @media (min-width: ${xlarge}) {
    font-size: 39px;
    line-height: 49px;
  }
`;

const Body = () => {
  return (
    <Copy>
      2019 – Wow, what a year!
      <br />
      To celebrate, we cordially invite you for drinks.
    </Copy>
  );
};

export default Body;
