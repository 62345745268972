import React from "react";
import styled from "styled-components";

import { between, xlarge, appear, staggeredAppear } from "../../utils/mixins";

// import { createUniqueName } from '../../utils/forms'

const Copy = styled.p`
  font-size: ${between(16, 20, 320, 1024)};
  line-height: 1.25em;
  text-transform: uppercase;
  animation: ${appear} 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  ${staggeredAppear(2)}
  @media (min-width: ${xlarge}) {
    font-size: 25px;
  }
`;

const Details = ({ name, label, required, theme, type }) => {
  return (
    <Copy theme={theme}>
      <a
        href="https://goo.gl/maps/uBQPRc45ece5xUSq8"
        target="_blank"
        rel="noopener noreferrer"
      >
        Lugosi bar
      </a>
      , Reichenberger&nbsp;str.&nbsp;152, 10999&nbsp;berlin
      <br />
      28 November 2019, 7 pm
    </Copy>
  );
};

export default Details;
