// import firebase from "firebase";
import firebase from "firebase/app";
import "firebase/database";

let database = null;

export const initialiseDatabase = () => {
  // Initialize default app
  // Retrieve your own options values by adding a web app on
  // https://console.firebase.google.com
  firebase.initializeApp({
    apiKey: process.env.apiKey, // Auth / General Use
    databaseURL: "https://hyam-network-event-november.firebaseio.com/" // Realtime Database
  });

  // Get a reference to the database service
  database = firebase.database().ref("/rsvp");

  return null;
};

export const pushToBackend = data => {
  return database
    .push(data)
    .then(() => database.once("value"))
    .then(snapshot => snapshot.val());
};
